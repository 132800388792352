import React, {useEffect, useState} from 'react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { decodeJWTTokenInServer } from 'utilities/AuthenticationUtilities';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
export default function Form(data){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [disable, setDisable] = useState(false);
    const [buttonText, setButtonText] = useState("Sign In");
    const [requestError, setRequestError] = useState("")
    const [passwordStyle, setPasswordStyle] = useState('');

    const [hasResponded, setToHasResponded] = useState(false);
    const [isWrongPassword, setIsWrongPassword] = useState(false);

    const [user, setUser] = useState(null)
    const [mfaType, setMFAType] = useState(null)

    const [code, setCode] = useState(null)
    const [showCode, setShowCode] = useState(false)
    const [isWrongCode, setIsWrongCode] = useState(false)

    const [message, setMessage] = useState(null)
    
    const baseUrl = process.env.NEXT_PUBLIC_BACKEND_PATH;
    const router = useRouter();

    const [error, setError] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [tooManyConfirmsError, setTooManyConfirmsError] = useState(false);
    const [verificationError, setVerificationError] = useState(false);
    const [passwordContentsError, setPasswordContentsError] = useState("")
    const [showPasswordChange, setShowPasswordChange] = useState(false);
    const [currentUser, setCurrentUser] = useState(null)
    const [newPassword, setNewPassword] = useState('');
    const [noMailError, setNoMailError] = useState(false);
    const [mfaButtonText, setMfaButtonText] = useState("Submit Code")

    const handleLogin  = async (e, email, password) => {
      e.preventDefault();
      setDisable(true);
      try{
          setButtonText("Signing In...");
          setMessage(null)
          
          const user = await Auth.signIn(email, password);
          setUser(user)
          if(user.challengeName === 'SOFTWARE_TOKEN_MFA' || user.challengeName === 'SMS_MFA') {
            setDisable(false);
            setShowCode(true);
            setMFAType(user.challengeName)
          } else if (user.challengeName === 'MFA_SETUP') {
            setMessage("To sign in, please finish setting up your 2-Factor Authentication method.");
          } else if (user.challengeName === null || user.challengeName === undefined) {

            const cognitoJwt = (await Auth.currentSession()).getIdToken().getJwtToken();
            await decodeJWTTokenInServer(baseUrl + '/api/users/login', postLoginTransition, cognitoJwt);

          } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED'){
              if( user.username == null ||  user.username == ""){
                  setNoMailError(true);
              }else {
                setCurrentUser(user);
                setNewPassword('');
                setConfirmPassword('');
                setShowPasswordChange(true);
                setError(false);
                setTooManyConfirmsError(false);
                setVerificationError(false);
                setPasswordContentsError("");
              }
          }
      } catch(error){
        if (error.name == "PasswordResetRequiredException") {
          setMessage("Password reset required for user due to security reasons. To being the process, press 'Forgot Password?'");
          setDisable(false);
          setButtonText("Sign In");
        }
        else {
            console.log(error)
            setPassword('');
            
            setDisable(false);
            setMfaButtonText("Submit Code");
            setButtonText("Sign In");
            
            

            setIsWrongPassword(true);
            setPasswordStyle("border-red-500");
        }
      }
    }

    const handlePasswordChange = async () =>{
      setDisable(false); 
      setButtonText("Sign In"); 
      setPassword('')
      if(newPassword!==confirmPassword)
          setIsWrongPassword(true)
      else
      {

          let base_error = ""
          if (newPassword.length < 8 || newPassword.length > 99)
              base_error = "Password must be between 8 and 99 characters in length.\n"
          if (!newPassword.match(/(?=.*[a-z])/))
              base_error += "Password must contain at least one lowercase character.\n"
          if (!newPassword.match(/(?=.*[A-Z])/))
              base_error += "Password must contain at least one uppercase character.\n"
          if (!newPassword.match(/(?=.*[0-9])/))
              base_error += "Password must contain at least one digit.\n"
          if (!newPassword.match(/(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])/))
              base_error += "Password must contain at least one character from the following list: \n ^ $ * . [ ] { } ( ) ? \" ! @ # % & / \ , > < ' : ; | _ ~ ` \n"
          if (newPassword.match(/[^a-zA-Z0-9\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`]/))
          {
              base_error += "Password must not contain invalid characters. "
              let aux = newPassword
              while(aux.match(/[a-zA-Z0-9\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`]/))
              {
                  aux = aux.replace(/[a-zA-Z0-9\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`]/, '')
              }
              let invalidChars = aux.split('').filter(function(item, pos, self) {
                  return self.indexOf(item) == pos;
              }).join('');
              base_error += "Invalid characters: '" + invalidChars + "'"
          }
          if (base_error != "")
              setPasswordContentsError(base_error)
          else{
            const authenticationData = {
              Username: currentUser.username,
              Password: password
             };
            const authenticationDetails = new AuthenticationDetails(authenticationData);
            currentUser.authenticateUser(authenticationDetails, {
              newPasswordRequired: () =>{
                  Auth.completeNewPassword(
                    currentUser,
                    newPassword,
                    { 
                      name: currentUser.username,
                    }
                ).then((user) => {
                  setDisable(false);
                  setShowPasswordChange(false);
                }).catch(error => {
                  setRequestError(error.message)
                  console.log(error.message);
                })
              },
              onFailure: (error) =>{
                setRequestError(error.message)
                console.log(error)

              },
              onSuccess: (msj) => {
              }
            })
          }
      }  
  }

    const handleCode = async (e, user, code, mfaType) => {

      try {
      e.preventDefault()
      setDisable(true)
      const loggedUser = await Auth.confirmSignIn(
        user, // Return object from Auth.signIn()
        code, // Confirmation code
        mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );

      const cognitoJwt = (await Auth.currentSession()).getIdToken().getJwtToken();
      await decodeJWTTokenInServer(baseUrl + '/api/users/login', postLoginTransition, cognitoJwt);

      }
      catch(error){
        console.log(error)
        setCode('');
        
        setDisable(false);
        setButtonText("Sign In");
        setMfaButtonText("Submit Code");

        
        setIsWrongCode(true);
      }
    }

    const postLoginTransition = (last_admin_path, last_path) => {
      const railsResponse = JSON.parse(localStorage.getItem('rails_token'));
      if(railsResponse){
          var pathname = ("/users/pad") 
          router.push(pathname);
      }
    }
    
    useEffect(() => {
      if(localStorage.getItem('rails_token')!== undefined && localStorage.getItem('rails_token')!==null)
      {
        if(JSON.parse(localStorage.getItem('rails_token'))["data"] !== undefined && JSON.parse(localStorage.getItem('rails_token'))["data"] !== null)
          postLoginTransition(JSON.parse(localStorage.getItem('rails_token'))["data"]["last_admin_path"], JSON.parse(localStorage.getItem('rails_token'))["data"]["last_path"])
        else
          postLoginTransition()
      }
      else
        postLoginTransition()
    }, [])

    const backFromCode = () => {
      setShowCode(false);
      setPassword('');
      setEmail('');
      setCode('');
      setButtonText("Sign In");
      setMfaButtonText("Submit Code");
      setPasswordStyle('');
      setIsWrongCode(false);
      setMessage(null);
      setUser(null);
      setMFAType(false);
    }
    
    return (
        <div>
          {!showPasswordChange && 
          <div>
            {showCode && <form onSubmit={formEvent =>  {setMfaButtonText("Submitting..."); handleCode(formEvent, user, code, mfaType)}} className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="code" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  One-Time Password
                </label>
                <div className="mt-1">
                  <input onChange={codeEvent => {setCode(codeEvent.target.value); setIsWrongCode(false);}} value={code} className={"shadow appearance-none border rounded w-full py-4 px-3 text-gray-600 dark:text-gray-400 mb-3 leading-tight focus:outline-none focus:shadow-outline "} id="code"></input>
                  {isWrongCode && <p className="text-red-500 text-xs italic">The code you entered is invalid, or has expired. Please try again.</p>}
                </div>
              </div>
              <button
                  type="submit"
                  disabled={!code || disable}
                  className="w-full flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-omc hover:bg-green-omc-hover focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
                >
                  
                  {mfaButtonText}
                </button>
              <div className="text-center">
                  <button onClick={() => backFromCode()} className="block ml-per-10 -mt-2 w-30 text-center text-green-omc dark:text-gray-300 hover:text-green-omc-hover">
                          Back to Login
                  </button>
              </div>

            </form>}
            {!showCode && <form onSubmit={formEvent => handleLogin(formEvent, email, password)} className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  Email
                </label>
                <div className="mt-1">
                <input  onChange={emailEvent => {setEmail(emailEvent.target.value); setIsWrongPassword(false); setNoMailError(false); } }
                        className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 dark:text-gray-400 leading-tight focus:outline-none focus:shadow-outline" 
                        id="email" 
                        type="text"/>
                </div>
              </div>
    
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                  Password
                </label>
                <div className="mt-1">
                <input onChange={passwordEvent => {setPassword(passwordEvent.target.value); setIsWrongPassword(false); setPasswordStyle(''); setNoMailError(false)}} value={password} className={"shadow appearance-none border rounded w-full py-4 px-3 text-gray-600 dark:text-gray-400 mb-3 leading-tight focus:outline-none focus:shadow-outline " + passwordStyle} id="password" type="password"></input>
                {message !== null && <p className="text-red-500 text-xs italic">{message}</p>}
                {isWrongPassword && <p className="text-red-500 text-xs italic">The credentials that were submitted are incorrect.</p>}
                {noMailError && <p className="text-red-500 text-xs italic">This account has no email address associated to it. Please contact support.</p>}
                </div>
              </div>
    
    
              <div>
                <button
                  type="submit"
                  disabled={!email || !password || disable}
                  className="w-full flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-omc hover:bg-green-omc-hover focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
                >
                  
                  {buttonText}
                </button>
              </div>
            </form>}
          </div>
          }
          {showPasswordChange && 
          <div>
             
              <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                      New Password
                  </label>
                  <div className="mt-1">
                  <input onChange={passwordEvent => {setNewPassword(passwordEvent.target.value); setIsWrongPassword(false);}} value={newPassword} className={"shadow appearance-none border rounded w-full py-4 px-3 text-gray-600 dark:text-gray-400 mb-3 leading-tight focus:outline-none focus:shadow-outline "} id="password" type="password"></input>
                  </div>
              </div>
              <div>
                  <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                      Confirm New Password
                  </label>
                  <div className="mt-1">
                  <input onChange={passwordEvent => {setConfirmPassword(passwordEvent.target.value); setIsWrongPassword(false);}} value={confirmPassword} className={"shadow appearance-none border rounded w-full py-4 px-3 text-gray-600 dark:text-gray-400 mb-3 leading-tight focus:outline-none focus:shadow-outline "} id="password" type="password"></input>
                  {isWrongPassword && <p className="text-red-500 text-xs italic">The passwords do not match.</p>}
                  {requestError.length > 0 && <p className="text-red-500 text-xs italic">{requestError}</p>}
                  </div>
              </div>
              <div>
                <button
                    // disabled={!confirmationCode || !password || !confirmPassword}
                    onClick={() => handlePasswordChange()}
                    className="w-full flex justify-center py-4 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-omc hover:bg-green-omc-hover focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
                >
                    
                    Set Password
                </button>
              </div>
              {error && <p style={{textAlign: "start"}} className="text-red-500 text-sm italic pt-2">An error has occurred.</p>}
              {tooManyConfirmsError && <p style={{textAlign: "start"}} className="text-red-500 text-sm italic pt-2">There have been too many requests. Please wait a bit and try again.</p>}
              {verificationError && <p style={{textAlign: "start"}} className="text-red-500 text-sm italic pt-2">The verification code is invalid. Please check your email for the verification code.</p>}
              {passwordContentsError != "" && <p style={{textAlign: "start"}} className="text-red-500 text-sm italic pt-2">{passwordContentsError}</p>}
              <div className="text-center">
                  <button onClick={() => {setShowPasswordChange(false); setDisable(false); setButtonText("Sign In"); setPassword('')}} className="block ml-per-10 pt-2 w-30 text-center text-green-omc dark:text-gray-300 hover:text-green-omc-hover">
                          Back to Login
                  </button>
              </div>
          </div>
          
          } 
        </div>

    );
};
