import Head from 'next/head';
import FormContainer from 'components/Form/FormContainer';
import AppVersion from 'components/Standalone/AppVersion';

export default function Login(){
    return (
        <div className="bg-grey-omc h-screen">
            <Head>
                <link rel="icon" href="/logo/icon.svg"/>
                <title>OMC International :: Login</title> 
            </Head>
            <FormContainer/>
            <AppVersion style="fixed bottom-2 right-6 ml-auto text-xs italic text-black dark:text-white"/>
        </div>
    )
}
