import axios from 'axios';

/**
 * @description Decodes the JWT on the server, if the token is decoded successfully
 * a rails_token and a rails_token_received key-pair is stored in localStorage.
 * 
 * @param {Link to server login endpoint} URL 
 * @param {setState to the responded variable, triggered when positive response is received from server} setToHasResponded 
 * @param {The JWT token that was received from Cognito} currentJwtToken 
 */
export async function decodeJWTTokenInServer(URL, postLoginTransition, cognitoJWT){
    await axios.get(URL, {
        headers : {
            'Content-Type':'application/json',
            Authorization: "Bearer " + cognitoJWT
        }
    }).then(response => {
        localStorage.setItem('rails_token',JSON.stringify(response));
        postLoginTransition(response["data"]["last_admin_path"], response["data"]["last_path"]);
    });
};