import axios from 'axios';
import { memoizedRefreshToken } from "./refreshToken";
const baseUrl = process.env.NEXT_PUBLIC_BACKEND_PATH
const axiosParent = () => {
    const axiosInstance = axios.create({
        baseUrl
    });


    // Add a response interceptor to handle 401 errors
    axiosInstance.interceptors.response.use(
      
      (response) => {return response},
      
      async function (error) {
      
        const originalRequest = error.config;
        
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          
          if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;
            
            var aux_token = await memoizedRefreshToken();
            originalRequest["headers"]["Authorization"]="Bearer " + aux_token;
            
            return axiosInstance(originalRequest);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log("The request was made but no response was received.", error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error when setting up the request', error.message);
        }        
        
        // console.log("Error Config", error.config);        
        return Promise.reject(error);
      }
    );
    return axiosInstance;
}


export default axiosParent;
