import mem from 'mem';
import Auth from '@aws-amplify/auth';
import axiosParent from "utilities/axiosInstance";
import Router from 'next/router';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
const refreshToken = async () => {
  
  const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  };
  
    return new Promise( async (resolve, reject) => {
      try {

        if (localStorage.getItem("is_federated") != null) {

          let refresh_token;

          for (var i = 0; i < localStorage.length; i++) {
            let temp_key = localStorage.key(i)

            if (temp_key.includes("refreshToken")) {
              refresh_token = localStorage.getItem(temp_key);
            }
          }

          const baseUrl = process.env.NEXT_PUBLIC_BACKEND_PATH
          axiosParent().post(baseUrl + `/api/users/federated_refresh`, {
            refresh_token: refresh_token
          },{
            headers : {
                'Content-Type':'application/json',
            }
          }).then(response => {
            const cognitoJwt = response.data.jwt
            // let u = Auth.federatedSignIn(
            //     'login.microsoftonline.com',
            //     { token: response.data.jwt, expires_at: response.data.exp },
            //     { name: response.data.cognito_username, email: response.data.email}
            //   );
            // console.log(u)

            // create an object with the UserPoolId and ClientId
            var poolData = {
                UserPoolId: process.env.NEXT_PUBLIC_AWS_USER_POOL_ID,
                ClientId: process.env.NEXT_PUBLIC_AWS_WEB_CLIENT_ID,
            };
            
            // pass the poolData object to CognitoUserPool
            var userPool = new AmazonCognitoIdentity.CognitoUserPool(
                poolData
            );

            // create an object containing the username and user pool.  You can get the username from the accessToken 
            // using the AmazonCognitoIdentity.CognitoAccessToken class (from previous post)
            var userData = {
                Username: response.data.username,
                Pool: userPool,
            };

            
            // create a cognito user using the userData object
            var cognitoUser = new AmazonCognitoIdentity.CognitoUser(
                userData
            );

            const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
                AccessToken: response.data.resp.access_token,
            });
                        
            const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
                IdToken: response.data.resp.id_token,
            });
        
            const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
                RefreshToken: refresh_token,
            });
        
            const sessionData = {
                IdToken: IdToken,
                AccessToken: AccessToken,
                RefreshToken: RefreshToken
            };
        
            const session = new AmazonCognitoIdentity.CognitoUserSession(
                sessionData
            );
            
            // set the cognito user session w/ the session created in my previous post from
            // calling the AmazonCognitoIdentity.CognitoUserSession() class with the Cognito tokens
            cognitoUser.setSignInUserSession(session);
            window.localStorage.setItem('is_federated', true);

            let token =  JSON.parse(window.localStorage.getItem('rails_token'))

            token["data"]["jwt"] = response.data.jwt
            token["data"]["expiry"] = response.data.expiry
            
            localStorage.setItem('rails_token',JSON.stringify(token));
    
            resolve(response.data.jwt);
          }).catch(e => {
            console.log(e);
            localStorage.clear();
            reject(e);    
            Router.push("/");
          });
        } else {
          let user = await Auth.currentAuthenticatedUser({bypassCache: true});

          const currentSession = await Auth.currentSession({bypassCache: true});
          
          await user.refreshSession(currentSession.refreshToken, (err, session) => {
            // session = null
            try {
              const idToken = session.idToken.jwtToken;
              const new_token = parseJwt(idToken)
              let token =  JSON.parse(window.localStorage.getItem('rails_token'))

              token["data"]["jwt"] = idToken
              token["data"]["expiry"] = new_token.exp
              
              localStorage.setItem('rails_token',JSON.stringify(token));
      
              resolve(idToken);
            } catch (e) {
              console.log(e)
              localStorage.clear()
              reject(e)
              Router.push("/");
            }
          });
        }
      } catch (e) {
        console.log(e)
        localStorage.clear();
        reject(e);
        Router.push("/");
      }
    });
  }

  const maxAge = 6000;

export const memoizedRefreshToken = mem(refreshToken, {
  maxAge,
});